import { createRouter, createWebHistory } from 'vue-router';
import AuthenticatedLayout from '@/layouts/AuthenticatedLayout';
import UnauthenticatedLayout from '@/layouts/UnauthenticatedLayout';

import ForgotPasswordPage from '@/pages/login/ForgotPasswordPage';
import LoginPage from '@/pages/login/LoginPage';

import DashboardPage from '@/pages/dashboard/DashboardPage';
import ClientsPage from '@/pages/directory/ClientsPage.vue';
import DocumentationPage from '@/pages/documentation/DocumentationPage';
import SettingsPage from '@/pages/settings/SettingsPage';

import NotFound from '@/NotFound';
import ClientPage from '@/pages/directory/ClientPage.vue';
import SmartleadPage from '@/pages/smartlead/SmartleadPage.vue';
import HeyreachPage from '@/pages/heyreach/HeyreachPage.vue';
import ExtensionPage from '@/pages/extension/ExtensionPage.vue';

const routes = [
  {
    path: '/auth',
    component: UnauthenticatedLayout,
    children: [
      { path: 'login', name: 'login', component: LoginPage },
      {
        path: 'forgot-password',
        name: 'forgot-password',
        component: ForgotPasswordPage
      }
    ]
  },
  {
    path: '/',
    component: AuthenticatedLayout,
    meta: { requiresAuth: true },
    children: [
      { path: '', name: 'dashboard', component: DashboardPage },
      {
        path: 'clients',
        name: 'clients',
        component: ClientsPage
      },
      {
        path: 'clients/:id',
        name: 'client',
        component: ClientPage
      },
      {
        path: 'extension',
        name: 'extension',
        component: ExtensionPage
      },
      {
        path: 'heyreach',
        name: 'heyreach',
        component: HeyreachPage
      },
      {
        path: 'smartlead',
        name: 'smartlead',
        component: SmartleadPage
      },
      {
        path: 'documentation',
        name: 'documentation',
        component: DocumentationPage
      },
      { path: 'settings', name: 'settings', component: SettingsPage }
    ]
  },
  // 404 Handler
  {
    path: '/:pathMatch(.*)*',
    component: UnauthenticatedLayout,
    children: [{ path: '', name: '404', component: NotFound }]
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  const authToken = localStorage.getItem('authToken');
  const isAuthenticated =
    authToken && Math.floor(Date.now() / 1000) < authToken;

  if (to.meta.requiresAuth && !isAuthenticated) {
    next('/auth/login');
  } else if (!to.meta.requiresAuth && isAuthenticated) {
    next('/');
  } else {
    next();
  }
});

export default router;
