<template>
  <div class="col-md-8 col-lg-6 col-xxl-3">
    <div class="card mb-0">
      <div class="card-body pt-5">
        <a href="/" class="text-nowrap logo-img text-center d-block mb-4 w-100">
          <img src="/assets/images/logos/light-logo.svg" style="width:240px; height: 36px;" class="light-logo"
               alt="Logo-light" />
        </a>
        <div class="mb-5 text-center">
          <p class="mb-0 ">
            Please enter your email address
          </p>
        </div>
        <form>
          <div class="form-floating mb-3">
            <input type="email" class="form-control" id="exampleInputEmail1" placeholder="Email" aria-describedby="emailHelp">
            <label><i class="ti ti-mail me-2 fs-4"></i>Email</label>
          </div>
          <a href="javascript:void(0)" class="btn btn-primary w-100 py-8 mb-3">Forgot Password</a>
          <router-link :to="{ name: 'login' }" class="btn bg-primary-subtle text-primary w-100 py-8">Back to
            Login
          </router-link>
        </form>
      </div>
    </div>
  </div>
</template>
<script setup>
</script>
