<template>
  <div class="p-9 py-3 border-bottom chat-meta-user d-flex align-items-center justify-content-between">
    <h5 class="text-dark mb-0 fw-semibold">Mailboxes</h5>
  </div>
  <div v-if="mailboxes && !mailboxes.length" class="p-9 text-center">
    <h6 class="my-5">No mailboxes added yet.</h6>
    <button class="btn btn-primary" @click="showCreateModal = true">Add Mailbox</button>
  </div>
  <div v-if="mailboxes.length" class="p-9">
    <div class="d-flex justify-content-end my-3">
      <button class="btn btn-primary" @click="showCreateModal = true"><i class="fa fa-plus-circle"></i> Add</button>
    </div>
    <table class="table table-hover table-striped table-sm w-75 table-fixed" id="mailboxes">
      <thead>
        <tr>
          <th>Email</th>
          <th>Tenant</th>
          <th class="text-center">Actions</th>
        </tr>
      </thead>
      <tbody v-if="mailboxes">
        <MailboxRow v-for="mailbox in mailboxes" :mailbox="mailbox" :key="mailbox.id" @updated="getMailboxes" @deleted="getMailboxes" />
      </tbody>
    </table>
  </div>
  <Teleport to="body" v-if="showCreateModal">
    <div class="modal fade show d-block" tabindex="-1" aria-labelledby="vertical-center-modal" aria-modal="true" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header d-flex align-items-center">
            <h4 class="modal-title" id="myLargeModalLabel">
              Add Mailbox
            </h4>
            <button type="button" class="btn-close" @click="closeCreateModal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form>
              <div :class="['form-floating mb-3', { 'has-danger': !isFieldValid('email') }]">
                <input type="email" class="form-control" placeholder="Email" v-model="fields.email.value" />
                <label><i class="ti ti-inbox me-2 fs-4"></i>Email</label>
                <small class="form-control-feedback" v-if="!isFieldValid('email')">
                  Please enter an email
                </small>
              </div>
              <div :class="['form-floating mb-3', { 'has-danger': !isFieldValid('tenant') }]">
                <input type="email" class="form-control" placeholder="Tenant" v-model="fields.tenant.value" />
                <label><i class="ti ti-building me-2 fs-4"></i>Tenant</label>
                <small class="form-control-feedback" v-if="!isFieldValid('tenant')">
                  Please enter a tenant
                </small>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary font-medium waves-effect text-start" @click="addMailbox">
              Create
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show"></div>
  </Teleport>
</template>

<script setup>
import { defineProps, onMounted, ref } from 'vue';
import { useApi } from '@/helpers/udab-api';
import { useFormValidation } from '@/helpers/form-validation';
import { useToast } from 'vue-toastification';
import MailboxRow from '@/pages/directory/client/mailboxes/MailboxRow.vue';

const props = defineProps({
  client: Object
});

const toast = useToast();
const api = useApi();

const showCreateModal = ref(false);
let mailboxes = ref([]);

const { fields, validate, isFieldValid, fieldValues, resetForm } = useFormValidation({
  email: { value: '', validate: 'email' },
  tenant: { value: '', validate: 'not-empty' }
});

const closeCreateModal = () => {
  resetForm();
  showCreateModal.value = false;
};

const getMailboxes = async () => {
  mailboxes.value = await api.get('/mailboxes', { client: props.client.id });
};

onMounted(getMailboxes);

const addMailbox = async () => {
  if (validate()) {
    const data = fieldValues();
    const response = await api.post('/mailboxes', { ...data, client: props.client.id });

    if (response) {
      getMailboxes();
      closeCreateModal();
      resetForm();
      toast.success('Mailbox successfully added');
    }
  }
};
</script>

<style scoped>
table#mailboxes td {
  vertical-align: middle;
}
</style>
