<template>
  <div class="card bg-info-subtle shadow-none position-relative overflow-hidden mb-4">
    <div class="card-body px-4 py-3">
      <h4 class="fw-semibold mb-8">Documentation</h4>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a class="text-muted text-decoration-none" href="/">Home</a>
          </li>
          <li class="breadcrumb-item" aria-current="page">Documentation</li>
        </ol>
      </nav>
    </div>
  </div>
  <div class="card w-100">
    <div class="card-body p-4">
      <ul class="nav nav-underline" id="myTab" role="tablist">
        <li class="nav-item me-3" role="presentation" v-for="tab in ['ERD', 'Data Dictionary']" :key="tab">
          <a :class="['nav-link', { 'active': activeTab === tab }]" data-bs-toggle="tab" role="tab"
             href="javascript: void(0)" @click="activeTab = tab">
            <span>{{ tab }}</span>
          </a>
        </li>
      </ul>
      <div class="tab-content tabcontent-border pt-3">
        <div role="tabpanel" :class="['tab-pane', 'fade', 'pt-3', { 'active show': activeTab === 'ERD' }]">
          <i class="fa fa-spin fa-circle-notch fa-2x" v-if="!erd"></i>
          <div id="erd-image" v-else>
            <p><a :href="erdImage" download="udab_erd.png" class="btn btn-light-indigo"><i
              class="fa fa-download me-2"></i> Download the ERD</a></p>
            <img :src="erdImage"/>
          </div>
        </div>
        <div role="tabpanel"
             :class="['tab-pane', 'fade', 'pt-3', { 'active show': activeTab === 'Data Dictionary' }]">
          <div class="d-flex align-items-center">
            <strong class="me-3">Table:</strong>
            <select class="form-select fw-semibold w-auto" v-model="selectedTable">
              <option value=""></option>
              <option v-for="table in Object.keys(dataDictionary)" :key="table" :value="table">{{ table }}
              </option>
            </select>
          </div>
          <template v-if="selectedTable">
            <h5 class="my-5">{{ dataDictionary[selectedTable].table }}</h5>
            <div class="table-responsive">
              <table class="table align-middle text-nowrap mb-0 table-striped table-sm table-hover">
                <thead>
                  <tr class="text-muted fw-semibold">
                    <th scope="col" class="ps-0">Column</th>
                    <th scope="col">Type</th>
                    <th scope="col">Description</th>
                  </tr>
                </thead>
                <tbody class="border-top">
                  <tr v-for="(data, column) in dataDictionary[selectedTable].columns" :key="column">
                    <td>
                      <p class="mb-0 fs-3 text-dark">{{ column }} <i
                        class="fa fa-exclamation-circle ms-2 text-danger"
                        v-if="data.comment?.includes('[deprecated]')"></i></p>
                    </td>
                    <td>
                      <p class="mb-0 fs-3 text-dark">{{ data.type }}</p>
                    </td>
                    <td>
                      <p class="mb-0 fs-3 text-dark" v-html="formatDescription(data.comment)"></p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, watchEffect } from 'vue';
import { useApi } from '@/helpers/udab-api';

const api = useApi();
const activeTab = ref('ERD');
const erd = ref(null);
const erdImage = ref('');
const dataDictionary = ref({});
const selectedTable = ref(null);

const formatDescription = (comment) => {
  if (!comment) return '';
  return comment.replace(
    '[deprecated]',
    '<span class="badge fw-semibold py-1 ms-3 bg-danger-subtle text-danger">Deprecated</span>'
  );
};

onMounted(async () => {
  erd.value = await api.get('/documentation/erd', null, { responseType: 'blob', showLoader: false });
  dataDictionary.value = await api.get('/documentation/data-dictionary', null, { showLoader: false });
});

watchEffect(() => {
  if (erd.value) {
    erdImage.value = URL.createObjectURL(erd.value);
  }
});

onUnmounted(() => {
  if (erdImage.value) {
    URL.revokeObjectURL(erdImage.value);
  }
});
</script>

<style scoped>
#erd-image {
  a:hover {
    opacity: .8;
  }

  img {
    max-width: 90%;
  }
}

table {
  table-layout: fixed;
}
</style>
