<template>
  <div class="card bg-info-subtle shadow-none position-relative overflow-hidden mb-4">
    <div class="card-body px-4 py-3">
      <h4 class="fw-semibold mb-8">HeyReach Instances</h4>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a class="text-muted text-decoration-none" href="/">Home</a>
          </li>
          <li class="breadcrumb-item" aria-current="page">HeyReach</li>
        </ol>
      </nav>
    </div>
  </div>

  <div class="col-lg-8 col-md-12 d-flex align-items-strech">
    <div class="card w-100">
      <div class="card-body p-4">
        <div v-if="instances && !instances.length" class="p-9 text-center">
          <h6 class="my-5">No instances added yet.</h6>
          <button class="btn btn-primary" @click="showCreateModal = true">Add Instance</button>
        </div>
        <template v-if="instances && instances.length">
          <div class="d-flex justify-content-end mb-3">
            <button class="btn btn-primary" @click="showCreateModal = true">
              <i class="fa fa-plus-circle"></i>
              <span class="ms-2">Create</span>
            </button>
          </div>
          <table id="instances" class="table table-hover table-fixed">
            <thead>
              <tr>
                <th>Label</th>
                <th>API Key</th>
                <th class="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              <InstanceRow v-for="instance in instances" :instance="instance" :key="instance.id"
                           @updated="getInstances" @archived="instanceArchived"/>
            </tbody>
          </table>
        </template>
      </div>
    </div>
  </div>
  <Teleport to="body" v-if="showCreateModal">
    <div class="modal fade show d-block" tabindex="-1" aria-labelledby="vertical-center-modal" aria-modal="true"
         role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header d-flex align-items-center">
            <h4 class="modal-title" id="myLargeModalLabel">
              Add Instance
            </h4>
            <button type="button" class="btn-close" @click="closeCreateModal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form>
              <div :class="['form-floating mb-3', { 'has-danger': !isFieldValid('label') }]">
                <input type="text" class="form-control" placeholder="Label"
                       v-model="fields.label.value"/>
                <label><i class="ti ti-building me-2 fs-4"></i>Label</label>
                <small class="form-control-feedback" v-if="!isFieldValid('label')">
                  Please enter a label
                </small>
              </div>
              <div :class="['form-floating mb-3', { 'has-danger': !isFieldValid('api_key') }]">
                <input type="text" class="form-control" placeholder="API Key"
                       v-model="fields.api_key.value"/>
                <label><i class="ti ti-building me-2 fs-4"></i>API Key</label>
                <small class="form-control-feedback" v-if="!isFieldValid('api_key')">
                  Please enter an API Key
                </small>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary font-medium waves-effect text-start"
                    @click="addInstance">
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show"></div>
  </Teleport>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useApi } from '@/helpers/udab-api';
import { useToast } from 'vue-toastification';
import { useFormValidation } from '@/helpers/form-validation';
import InstanceRow from '@/pages/heyreach/InstanceRow.vue';

const api = useApi();
const toast = useToast();
const instances = ref(null);

const showCreateModal = ref(false);

const { fields, validate, isFieldValid, fieldValues, resetForm } = useFormValidation({
  label: { value: '', validate: 'not-empty' },
  api_key: { value: '', validate: 'not-empty' }
});

const closeCreateModal = () => {
  resetForm();
  showCreateModal.value = false;
};

const getInstances = async () => {
  instances.value = await api.get('/heyreach-instances');
};

onMounted(async () => {
  getInstances();
});

const addInstance = async () => {
  if (validate()) {
    const data = fieldValues();
    const response = await api.post('/heyreach-instances', data);

    if (response) {
      getInstances();
      closeCreateModal();
      resetForm();
      toast.success('Instance successfully added. Data sync in progress');
    }
  }
};

const instanceArchived = () => {
  getInstances();
  toast.success('Instance successfully archived');
};
</script>
<style scoped>
#instances th,
#instances td {
  vertical-align: middle;
}

#instances th:nth-child(1),
#instances td:nth-child(1) {
  width: 25%;
}

#instances th:nth-child(2),
#instances td:nth-child(2) {
  width: 50%;
}

#instances th:nth-child(3),
#instances td:nth-child(3) {
  width: 25%;
}
</style>
