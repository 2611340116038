<template>
  <tr class="align-middle">
    <td>
      <template v-if="!editing">{{ instance.email }}</template>
      <div v-if="editing" class="d-flex align-items-center justify-content-center">
        <input type="email" class="form-control mx-2" v-model="fields.email.value" />
      </div>
    </td>
    <td>
      <template v-if="!editing">{{ instance.api_key }}</template>
      <div v-if="editing" class="d-flex align-items-center justify-content-center">
        <input type="text" class="form-control mx-2" v-model="fields.api_key.value" />
      </div>
    </td>
    <td class="text-center">
      <template v-if="!editing">
        <button class="btn btn-primary me-2" @click="archiveInstance"><i class="fa fa-times"></i></button>
        <button class="btn btn-info" @click="editing=true"><i class="fa fa-edit"></i></button>
      </template>
      <template v-if="editing">
        <button class="btn btn-secondary me-2" @click="saveInstance"><i class="fa fa-save"></i></button>
        <button class="btn btn-outline-danger" @click="editing=false"><i class="fa fa-times"></i></button>
      </template>
    </td>
  </tr>
</template>
<script setup>
import { defineProps, ref, defineEmits, onMounted } from 'vue';
import swal from 'sweetalert';
import TagComponent from '@/components/TagComponent.vue';
import { capitalizeFirstLetter } from '@/helpers/util';
import { useApi } from '@/helpers/udab-api';
import { useFormValidation } from '@/helpers/form-validation';

const emit = defineEmits(['deleted', 'updated']);
const { fields, validate, fieldValues } = useFormValidation({});
const api = useApi();
const editing = ref(false);

const props = defineProps({
  instance: {
    type: Object,
    required: true
  }
});

onMounted(() => {
  fields.value = {
    email: { value: props.instance.email, validate: 'email' },
    api_key: { value: props.instance.api_key, validate: 'not-empty' }
  };
});

const saveInstance = async () => {
  if (validate()) {
    const data = fieldValues();
    const response = await api.put(`/smartlead-instances/${props.instance.id}`, data);

    if (response) {
      emit('updated');
      editing.value = false;
    }
  }
};

const archiveInstance = () => {
  swal({
    title: 'Are you sure?',
    icon: 'warning',
    buttons: true,
    dangerMode: true
  })
    .then(async (confirmed) => {
      if (confirmed) {
        await api.deleteRequest(`/smartlead-instances/${props.instance.id}`);
        emit('archived');
      }
    });
};
</script>
