<template>
  <div class="card bg-info-subtle shadow-none position-relative overflow-hidden mb-4">
    <div class="card-body px-4 py-3">
      <h4 class="fw-semibold mb-8">Settings</h4>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a class="text-muted text-decoration-none" href="/">Home</a>
          </li>
          <li class="breadcrumb-item" aria-current="page">Settings</li>
        </ol>
      </nav>
    </div>
  </div>

  <div class="col-xl-8 d-flex align-items-strech">
    <div class="card w-100">
      <div class="card-body p-4">
        <ul class="nav nav-underline" id="myTab" role="tablist">
          <li class="nav-item me-3" role="presentation" v-for="tab in ['Settings', 'Tags']" :key="tab">
            <a :class="['nav-link', { 'active': activeTab === tab }]" data-bs-toggle="tab" role="tab"
               href="javascript: void(0)" @click="activeTab = tab">
              <span>{{ tab }}</span>
            </a>
          </li>
        </ul>
        <div class="tab-content tabcontent-border pt-3">
          <div role="tabpanel" :class="['tab-pane', 'fade', 'pt-3', { 'active show': activeTab === 'Settings' }]">
            <template v-if="settings">
              <div v-for="setting in settings" :key="setting.id" class="row mb-3">
                <label :for="setting.setting_key" class="col-sm-3 col-form-label">{{ setting.label }}</label>
                <div class="col-sm-9">
                  <input v-if="['text', 'password'].includes(setting.type)" class="form-control"
                         :type="setting.type" v-model="setting.setting_value" />

                  <select v-else-if="setting.type === 'select'" v-model="setting.setting_value" :name="setting.setting_key" class="form-select">
                    <option v-for="(option, i) in setting.data" :key="i" :value="option">{{ option }}</option>
                  </select>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="tab-content tabcontent-border pt-3">
          <div role="tabpanel" :class="['tab-pane', 'fade', 'pt-3', { 'active show': activeTab === 'Tags' }]">
            <div class="d-flex justify-content-end mb-3">
              <button class="btn btn-primary" @click="showCreateModal=true">
                <i class="fa fa-plus-circle"></i>
                <span class="ms-2">Create</span>
              </button>
            </div>
            <table id="tags" class="table table-hover table-fixed">
              <thead>
                <tr>
                  <th>Object</th>
                  <th class="text-center">Name</th>
                  <th class="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                <TagRow v-for="tag in tags" :tag="tag" :key="tag.id" @updated="getTags" @deleted="tagDeleted" />
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div v-if="activeTab === 'Settings'" class="card-footer p-4 text-center">
        <button type="button" class="btn btn-primary px-5" @click="save">
          Save
          <i class="fa fa-spin fa-spinner ms-1" v-if="false"></i>
        </button>
      </div>
    </div>
  </div>
  <Teleport to="body" v-if="showCreateModal">
    <div class="modal fade show d-block" tabindex="-1" aria-labelledby="vertical-center-modal" aria-modal="true" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header d-flex align-items-center">
            <h4 class="modal-title" id="myLargeModalLabel">
              Create Tag
            </h4>
            <button type="button" class="btn-close" @click="closeCreateModal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form>
              <div :class="['form-floating mb-3', { 'has-danger': !isFieldValid('object') }]">
                <select class="form-select" v-model="fields.object.value" required>
                  <option value="client">Client</option>
                </select>
                <label><i class="ti ti-bolt me-2 fs-4"></i>Object</label>
                <small class="form-control-feedback" v-if="!isFieldValid('object')">
                  Please select an object type
                </small>
              </div>
              <div :class="['form-floating mb-3', { 'has-danger': !isFieldValid('name') }]">
                <input type="text" class="form-control" placeholder="Tag" v-model="fields.name.value" />
                <label><i class="ti ti-building me-2 fs-4"></i>Name</label>
                <small class="form-control-feedback" v-if="!isFieldValid('name')">
                  Please enter a tag name
                </small>
              </div>
              <div class="form-floating mb-4">
                <input type="color" class="form-control" v-model="fields.color.value" />
                <label><i class="ti ti-building me-2 fs-4"></i>Color</label>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary font-medium waves-effect text-start" @click="createTag">
              Create
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show"></div>
  </Teleport>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useApi } from '@/helpers/udab-api';
import { useToast } from 'vue-toastification';
import { useFormValidation } from '@/helpers/form-validation';
import TagRow from '@/pages/settings/TagRow.vue';

const api = useApi();
const toast = useToast();
const settings = ref(null);
const activeTab = ref('Settings');
const tags = ref([]);

const showCreateModal = ref(false);

const { fields, validate, isFieldValid, fieldValues, resetForm } = useFormValidation({
  object: { value: '', validate: 'not-empty' },
  name: { value: '', validate: 'not-empty' },
  color: { value: '#683BAB' }
});

const closeCreateModal = () => {
  resetForm();
  showCreateModal.value = false;
};

const getTags = async () => {
  tags.value = await api.get('/tags');
};

onMounted(async () => {
  settings.value = await api.get('/settings', { public: true });
  getTags();
});

const save = async () => {
  const data = settings.value.map(({ id, setting_key, setting_value }) => ({
    id,
    setting_key,
    setting_value
  }));

  settings.value = await api.put('/settings', data);
  toast.success('Settings updated');
};

const createTag = async () => {
  if (validate()) {
    const data = fieldValues();
    const response = await api.post('/tags', data);

    if (response) {
      getTags();
      closeCreateModal();
      resetForm();
      toast.success('Tag successfully created');
    }
  }
};

const tagDeleted = () => {
  getTags();
  toast.success('Tag successfully deleted');
};
</script>
<style scoped>
#tags td {
  vertical-align: middle;
}
</style>
