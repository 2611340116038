<template>
  <div
    :class="['position-relative', 'overflow-hidden', 'min-vh-100', 'w-100', 'd-flex', 'align-items-center', 'justify-content-center', { 'radial-gradient': routeName !== '404'}]">
    <div class="d-flex align-items-center justify-content-center w-100">
      <div class="row justify-content-center w-100">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { initTheme } from '@/../public/assets/js/theme';

const route = useRoute();
const routeName = ref(route.name);

onMounted(initTheme);
</script>
