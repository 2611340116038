<template>
  <div class="card bg-info-subtle shadow-none position-relative overflow-hidden mb-4">
    <div class="card-body px-4 py-3">
      <h4 class="fw-semibold mb-8">Client Directory</h4>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a class="text-muted text-decoration-none" href="/">Home</a>
          </li>
          <li class="breadcrumb-item" aria-current="page">Client Directory</li>
        </ol>
      </nav>
    </div>
  </div>
  <div class="card w-100">
    <div class="card-body">
      <div class="filters row mb-5">
        <div class="col-md-3">
          <div class="d-flex">
            <Multiselect
              v-model="tagFilter"
              :options="tags"
              value-prop="id"
              mode="tags"
              :create-option="false"
              label="name"
              placeholder="Tags"
            >
              <template v-slot:option="{ option }">
                <TagComponent :tag="option" />
              </template>
              <template v-slot:tag="{ option, handleTagRemove }">
                <TagComponent :tag="option" :remove="handleTagRemove" />
              </template>
            </Multiselect>
          </div>
        </div>
        <div class="col-md-4 offset-md-1">
          <div class="d-flex">
            <Multiselect
              v-model="statusFilter"
              :options="clientStatuses"
              mode="tags"
              :create-option="false"
              placeholder="Status"
            />
          </div>
        </div>
        <div class="col-md-1 offset-md-3 text-right">
          <button class="btn btn-primary" @click="showCreateModal = true;">
            <i class="fa fa-plus-circle"></i>
            <span class="ms-2">Create</span>
          </button>
        </div>
      </div>
      <table id="clients" class="table table-hover" style="width:100%">
        <thead>
          <tr>
            <th>Name</th>
            <th class="text-center">Tags</th>
            <th class="text-center">Status</th>
            <th>Industry</th>
          </tr>
        </thead>
        <tfoot>
          <tr>
            <th>Name</th>
            <th class="text-center">Tags</th>
            <th class="text-center">Status</th>
            <th>Industry</th>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>

  <Teleport to="body" v-if="showCreateModal">
    <div class="modal fade show d-block" tabindex="-1" aria-labelledby="vertical-center-modal" aria-modal="true" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header d-flex align-items-center">
            <h4 class="modal-title" id="myLargeModalLabel">
              Create Client
            </h4>
            <button type="button" class="btn-close" @click="closeCreateModal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form>
              <div :class="['form-floating mb-3', { 'has-danger': !isFieldValid('name') }]">
                <input type="text" class="form-control" placeholder="Name" id="name" v-model="fields.name.value" />
                <label><i class="ti ti-building me-2 fs-4"></i>Name</label>
                <small class="form-control-feedback" v-if="!isFieldValid('name')">
                  Please enter a client name
                </small>
              </div>
              <div :class="['form-floating mb-4', { 'has-danger': !isFieldValid('sfdc_account_id') }]">
                <input type="text" class="form-control" id="sfdc_account_id" maxlength="18" placeholder="Salesforce ID" v-model="fields.sfdc_account_id.value" />
                <label><i class="ti ti-id me-2 fs-4"></i>Salesforce ID</label>
                <small class="form-control-feedback" v-if="!isFieldValid('sfdc_account_id')">
                  Salesforce ID must be 18 characters
                </small>
              </div>
              <div :class="['form-floating mb-4', { 'has-danger': !isFieldValid('industry') }]">
                <select class="form-select" v-model="fields.industry.value" required>
                  <option value=""></option>
                  <option v-for="industry in CLIENT_INDUSTRIES" :key="industry" :value="industry">{{ industry }}</option>
                </select>
                <label><i class="ti ti-bolt me-2 fs-4"></i>Industry</label>
                <small class="form-control-feedback" v-if="!isFieldValid('industry')">
                  Please enter an industry
                </small>
              </div>
              <div class="form-floating mb-4">
                <Multiselect
                  v-model="fields.tags.value"
                  :options="tags"
                  value-prop="id"
                  mode="tags"
                  :create-option="false"
                  label="name"
                  placeholder="Tags"
                >
                  <template v-slot:option="{ option }">
                    <TagComponent :tag="option" />
                  </template>
                  <template v-slot:tag="{ option, handleTagRemove }">
                    <TagComponent :tag="option" :remove="handleTagRemove" />
                  </template>
                  <template v-slot:placeholder>
                    <div class="multiselect-placeholder">
                      <i class="ti ti-tags me-2 fs-4"></i>
                      <span>Tags</span>
                    </div>
                  </template>
                </Multiselect>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary font-medium waves-effect text-start" @click="createClient">
              Create
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show"></div>
  </Teleport>
  <button @click="doExport" id="export-clients" class="btn btn-light-indigo ms-3">
    <i class="fa fa-download me-2"></i>
    <span>Export</span>
  </button>
</template>

<script setup>
import { ACTIVE, CLIENT_INDUSTRIES, CLIENT_STATUSES, ONBOARDING } from '@/helpers/constants';
import { onMounted, onUnmounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import Multiselect from '@vueform/multiselect';
import { useApi } from '@/helpers/udab-api';
import { useFormValidation } from '@/helpers/form-validation';
import { capitalizeFirstLetter, waitForElement } from '@/helpers/util';
import TagComponent from '@/components/TagComponent.vue';


const $ = window.jQuery;
const api = useApi();
const toast = useToast();
const router = useRouter();

let dataTable = null;
const tags = ref([]);
const tagFilter = ref([]);
const clientStatuses = CLIENT_STATUSES.map(status => {
  return { value: status, label: capitalizeFirstLetter((status)) };
});
const statusFilter = ref([ACTIVE, ONBOARDING]);
const showCreateModal = ref(false);

const { fields, validate, isFieldValid, fieldValues, resetForm } = useFormValidation({
  name: { value: '', validate: 'not-empty' },
  sfdc_account_id: { value: '', validate: 'exact-length', options: { length: 18 } },
  industry: { value: '', validate: 'not-empty' },
  tags: { value: [] }
});

const closeCreateModal = () => {
  resetForm();
  showCreateModal.value = false;
};

const fetchData = () => {
  if (dataTable) {
    dataTable.ajax.reload();
  }
};

const createClient = async () => {
  if (validate()) {
    const data = fieldValues();
    const response = await api.post('/clients', data);

    if (response) {
      fetchData();
      resetForm();
      closeCreateModal();
      toast.success('Client successfully created');
    }
  }
};

onMounted(async () => {
  tags.value = await api.get('/tags', { object: 'client' });

  dataTable = $('#clients').DataTable({
    processing: true,
    serverSide: true,
    ordering: false,
    searchDelay: 500,
    lengthMenu: [[25, 50, 100, -1], [25, 50, 100, 'All']],
    pageLength: 25,
    ajax: async ({ start, length, search, draw }, callback) => {
      const { recordsTotal, recordsFiltered, data } = await api.get('/clients/datatable', {
        start,
        length,
        search: search.value,
        status: statusFilter.value,
        tag: tagFilter.value
      }, { showLoader: false });

      callback({ draw, recordsTotal, recordsFiltered, data });
    },
    autoWidth: false,
    columns: [
      { data: 'name', width: '25%' },
      {
        data: 'tags',
        width: '25%',
        render: (tags) => {
          let value = '<div class="text-center">';
          for (let i in tags) {
            const tag = tags[i];
            value += `<div class="text-center d-inline-block"><span class="badge ms-1 me-1" style="background: ${tag.color}20; color: ${tag.color};">${tag.name}</span></div>`;
          }
          value += '</div>';
          return value;
        }
      },
      {
        data: 'status',
        width: '25%',
        render: (data) => `<div class="text-center"><span class="status status-${data}">${capitalizeFirstLetter(data)}</span></div>`
      },
      { data: 'industry', width: '25%' }
    ],
    createdRow: (row, data) => $(row).data('id', data.id),
    drawCallback: () => $('#clients tbody tr').click(function () {
      router.push({ name: 'client', params: { id: $(this).data('id') } });
    })
  });

  waitForElement('#clients_filter label', () => {
    $('#clients_filter label').after($('#export-clients'));
  });
});

const doExport = async () => {
  const response = await api.get('/clients/export', {
    status: statusFilter.value,
    tag: tagFilter.value
  }, { responseType: 'blob' });
  const currentDate = new Date().toISOString().slice(0, 10);
  const filename = `Abstrakt Clients - ${currentDate}.csv`;
  const url = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

watch([tagFilter, statusFilter], fetchData, { deep: true });

onUnmounted(() => {
  if (dataTable) {
    dataTable.destroy();
    dataTable = null;
  }
});
</script>


<style scoped>
.card-body {
  padding: 3rem;
}

#clients :deep(tr) {
  cursor: pointer;
}

#clients :deep(td) {
  vertical-align: middle;
}

html[data-bs-theme=light] #clients :deep(tr.even td) {
  background: #f8f8f8;
}
</style>
