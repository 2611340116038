<template>
  <div class="p-9 py-3 border-bottom chat-meta-user d-flex align-items-center justify-content-between">
    <h5 class="text-dark mb-0 fw-semibold">Spam Testing</h5>
  </div>
  <div class="p-9">
    Coming soon...
  </div>
</template>
<script setup>
</script>
