import { computed, ref } from 'vue';

const validators = {
  'not-empty': (value) => value.trim() !== '',
  'email': (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
  'exact-length': (value, options) => value.length === options.length
};

export function useFormValidation (fieldsSchema) {
  const initial = JSON.parse(JSON.stringify(fieldsSchema));
  const fields = ref(fieldsSchema);
  const enabled = ref(false);

  const validate = () => {
    enabled.value = true;
    return isFormValid.value;
  };

  const isFieldValid = (fieldName) => {
    const field = fields.value[fieldName];
    if (!enabled.value || !field.validate) {
      return true;
    }
    return validators[field.validate](field.value, field?.options);
  };

  const isFormValid = computed(() => {
    return Object.keys(fields.value).every((fieldName) =>
      isFieldValid(fieldName)
    );
  });

  const fieldValues = () => {
    return Object.keys(fields.value).reduce((acc, key) => {
      acc[key] = fields.value[key].value;
      return acc;
    }, {});
  };

  const resetForm = () => {
    fields.value = initial;
    enabled.value = false;
  };

  return { fields, validate, isFieldValid, isFormValid, fieldValues, resetForm };
}
