<template>
  <tr class="align-middle">
    <td>
      <template v-if="!editing">{{ mailbox.email }}</template>
      <input v-if="editing" v-model="fields.email.value" type="email" class="form-control mx-2 w-auto" />
    </td>
    <td>
      <template v-if="!editing">{{ mailbox.tenant }}</template>
      <input v-if="editing" v-model="fields.tenant.value" type="text" class="form-control mx-2 w-auto" />
    </td>
    <td class="text-center">
      <template v-if="!editing">
        <button class="btn btn-primary me-2" @click="deleteMailbox"><i class="fa fa-times"></i></button>
        <button class="btn btn-info" @click="editing=true"><i class="fa fa-edit"></i></button>
      </template>
      <template v-if="editing">
        <button class="btn btn-secondary me-2" @click="saveMailbox"><i class="fa fa-save"></i></button>
        <button class="btn btn-outline-danger" @click="editing=false"><i class="fa fa-times"></i></button>
      </template>
    </td>
  </tr>
</template>
<script setup>
import { defineProps, ref, defineEmits, onMounted } from 'vue';
import swal from 'sweetalert';
import { useApi } from '@/helpers/udab-api';
import { useFormValidation } from '@/helpers/form-validation';

const emit = defineEmits(['deleted', 'updated']);
const { fields, validate, fieldValues } = useFormValidation({});
const api = useApi();
const editing = ref(false);

const props = defineProps({
  mailbox: {
    type: Object,
    required: true
  }
});

onMounted(() => {
  fields.value = {
    email: { value: props.mailbox.email, validate: 'email' },
    tenant: { value: props.mailbox.tenant, validate: 'not-empty' }
  };
});

const saveMailbox = async () => {
  if (validate()) {
    const data = fieldValues();
    const response = await api.patch(`/mailboxes/${props.mailbox.id}`, data);

    if (response) {
      emit('updated');
      editing.value = false;
    }
  }
};

const deleteMailbox = () => {
  swal({
    title: 'Are you sure?',
    icon: 'warning',
    buttons: true,
    dangerMode: true
  })
    .then(async (confirmed) => {
      if (confirmed) {
        await api.deleteRequest(`/mailboxes/${props.mailbox.id}`);
        emit('deleted');
      }
    });
};
</script>
