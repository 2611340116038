<template>
  <div class="row">
    <div class="col">
      <div class="card border-0 zoom-in bg-primary-subtle shadow-none">
        <div class="card-body">
          <div class="text-center">
            <p class="fw-semibold fs-3 mb-1" style="color: #5d87ff;">
              Active Clients
            </p>
            <h5 class="fw-semibold mb-0" style="color: #5d87ff;">2,143</h5>
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card border-0 zoom-in bg-warning-subtle shadow-none">
        <div class="card-body">
          <div class="text-center">
            <p class="fw-semibold fs-3 text-warning mb-1">
              Active Sequences
            </p>
            <h5 class="fw-semibold text-warning mb-0">1,750</h5>
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card border-0 zoom-in bg-info-subtle shadow-none">
        <div class="card-body">
          <div class="text-center">
            <p class="fw-semibold fs-3 text-info mb-1">
              Manual Sequences
            </p>
            <h5 class="fw-semibold text-info mb-0">270</h5>
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card border-0 zoom-in bg-danger-subtle shadow-none">
        <div class="card-body">
          <div class="text-center">
            <p class="fw-semibold fs-3 text-danger mb-1">
              Sequencer Leads (24hr)
            </p>
            <h5 class="fw-semibold text-danger mb-0">12,327</h5>
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card border-0 zoom-in bg-secondary-subtle shadow-none">
        <div class="card-body">
          <div class="text-center">
            <p class="fw-semibold fs-3 mb-1" style="color: rgba(73, 190, 255);">
              List Leads (24hr)
            </p>
            <h5 class="fw-semibold  mb-0" style="color: rgba(73, 190, 255);">4,859</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
</script>
