export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const waitForElement = (selector, callback) => {
  const checkForElement = () => {
    if (document.querySelector(selector)) {
      callback();
    } else {
      setTimeout(waitForElement, 100);
    }
  };

  checkForElement();
};
