<template>
  <div class="card bg-info-subtle shadow-none position-relative overflow-hidden mb-4">
    <div class="card-body px-4 py-3">
      <h4 class="fw-semibold mb-8" v-if="client">{{ client.name }}</h4>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a class="text-muted text-decoration-none" href="/">Home</a>
          </li>
          <li class="breadcrumb-item">
            <router-link class="text-muted text-decoration-none" :to="{name: 'clients'}">Client Directory</router-link>
          </li>
          <li class="breadcrumb-item" aria-current="page" v-if="client">{{ client.name }}</li>
        </ol>
      </nav>
    </div>
  </div>
  <div class="card overflow-hidden chat-application">
    <div class="d-flex align-items-center justify-content-between gap-3 m-3 d-lg-none">
      <button class="btn btn-primary d-flex" type="button" data-bs-toggle="offcanvas" data-bs-target="#chat-sidebar" aria-controls="chat-sidebar">
        <i class="ti ti-menu-2 fs-5"></i>
      </button>
      <div class="position-relative w-100 opacity-0">
        <input type="text" class="form-control search-chat py-2 ps-5" id="text-srh" placeholder="Search Contact">
        <i class="ti ti-search position-absolute top-50 start-0 translate-middle-y fs-6 text-dark ms-3"></i>
      </div>
    </div>
    <div class="d-flex w-100">
      <div class="left-part border-end w-20 flex-shrink-0">
        <ul class="list-group" style="height: calc(100vh - 400px)" data-simplebar="init">
          <li class="list-group-item border-0 p-0 mx-9 subnav-item w-85">
            <a class="d-flex align-items-center gap-2 list-group-item-action text-dark px-3 py-8 mb-1 rounded-1"
               :class="{'active': tab.__name === 'TabProfile'}"
               href="javascript:void(0)"
               @click="tab = markRaw(TabProfile)"
            >
              <i class="fa fa-fw fa-id-card fs-5 fw-light"></i>Profile
            </a>
          </li>
          <li class="list-group-item border-0 p-0 mx-9 subnav-item w-85">
            <a class="d-flex align-items-center gap-2 list-group-item-action text-dark px-3 py-8 mb-1 rounded-1"
               :class="{'active': tab.__name === 'TabMailboxes'}"
               href="javascript:void(0)"
               @click="tab = markRaw(TabMailboxes)"
            >
              <i class="fa fa-fw fa-inbox fs-5"></i>Mailboxes </a>
          </li>
          <li class="list-group-item border-0 p-0 mx-9 subnav-item w-85">
            <a class="d-flex align-items-center gap-2 list-group-item-action text-dark px-3 py-8 mb-1 rounded-1 link-disabled"
               :class="{'active': tab.__name === 'TabDne'}"
               href="javascript:void(0)"
               @click="tab = markRaw(TabDne)"
            >
              <i class="ti ti-at-off fs-5 fw-light me-1"></i>DNE </a>
          </li>
          <li class="list-group-item border-0 p-0 mx-9 subnav-item w-85">
            <a class="d-flex align-items-center gap-2 list-group-item-action text-dark px-3 py-8 mb-1 rounded-1 link-disabled"
               :class="{'active': tab.__name === 'TabSequences'}"
               href="javascript:void(0)"
               @click="tab = markRaw(TabSequences)"
            >
              <i class="ti ti-whirl fs-5 fw-light me-1"></i>Sequences </a>
          </li>
          <li class="list-group-item border-0 p-0 mx-9 subnav-item w-85">
            <a class="d-flex align-items-center gap-2 list-group-item-action text-dark px-3 py-8 mb-1 rounded-1 link-disabled"
               :class="{'active': tab.__name === 'TabSpamTesting'}"
               href="javascript:void(0)"
               @click="tab = markRaw(TabSpamTesting)"
            >
              <i class="fa fa-fw fa-envelope fs-5 fw-light"></i>Spam Testing </a>
          </li>
        </ul>
      </div>
      <div class="d-flex w-100">
        <div class="w-100">
          <div class="chat-container h-100 w-100">
            <div class="chat-box-inner-part h-100">
              <div class="chatting-box app-email-chatting-box">
                <component :is="tab" :client="client" @update:client="client = $event"></component>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, markRaw, onMounted, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useApi } from '@/helpers/udab-api';
import TabProfile from './client/TabProfile.vue';
import TabMailboxes from './client/TabMailboxes.vue';
import TabDne from './client/TabDne.vue';
import TabSequences from './client/TabSequences.vue';
import TabSpamTesting from './client/TabSpamTesting.vue';

const api = useApi();
const route = useRoute();

const clientId = computed(() => route.params.id);
const client = ref(null);
const tab = ref(markRaw(TabProfile));

onMounted(async () => {
  client.value = await api.get(`/clients/${clientId.value}`);
});
</script>
<style scoped>
.link-disabled {
  opacity: 0.38;
}
</style>
