export const ACTIVE = 'active';
export const CANCELED = 'canceled';
export const ONBOARDING = 'onboarding';
export const PAUSED = 'paused';

export const CLIENT_STATUSES = [ACTIVE, CANCELED, ONBOARDING, PAUSED];

export const CLIENT_INDUSTRIES = [
  'Accounting Services',
  'Advertising & Marketing',
  'Aerospace & Defense',
  'Agriculture',
  'AI Content and Video',
  'Airlines, Airports & Air Services',
  'Allergy Testing',
  'Ambulance Services',
  'Amusement Parks, Arcades & Attractions',
  'Animals & Livestock',
  'Apparel & Accessories Retail',
  'Appliances',
  'Architecture, Engineering & Design',
  'Asset Management',
  'Auctions',
  'Audio Visual',
  'Audio Visual',
  'Automobile Parts Stores',
  'Automotive (Fleet Sales)',
  'Automotive Parts',
  'Automotive Service & Collision Repair',
  'Barber Shops & Beauty Salons',
  'Blood & Organ Banks',
  'Boats & Submarines',
  'Broadcasting',
  'Building Materials',
  'Business Intelligence (BI) Software',
  'Business Services',
  'Cable & Satellite',
  'Call Centers & Business Centers',
  'Cannabis Industry',
  'Car & Truck Rental',
  'Chambers of Commerce',
  'Chemicals & Related Products',
  'Childcare',
  'Civil Engineering Construction',
  'Cleaning Products',
  'Cleaning Services',
  'Colleges & Universities',
  'Commercial Banking',
  'Commercial Cleaning',
  'Commercial Electric',
  'Commercial Fire Protection',
  'Commercial Flooring',
  'Commercial Improvement',
  'Commercial Recycling',
  'Commercial Roofing',
  'Commercial Signs',
  'Computer Equipment & Peripherals',
  'Concrete/Asphalt Services',
  'Construction',
  'Consulting',
  'Consumer Electronics & Computers Retail',
  'Consumer Services',
  'Content & Collaboration Software',
  'Convenience Stores, Gas Stations & Liquor Stores',
  'Copy/Print',
  'Cosmetics, Beauty Supply & Personal Care Products',
  'Credit Cards & Transaction Processing',
  'Crops',
  'Cultural & Informational Centers',
  'Custom Software & IT Services',
  'Customer Relationship Management (CRM) Software',
  'Data Collection & Internet Portals',
  'Database & File Management Software',
  'Debt Collection',
  'Dental Offices',
  'Department Stores, Shopping Centers & Superstores',
  'Direct Mail & Printing',
  'Drug Stores & Pharmacies',
  'Education',
  'Elderly Care Services',
  'Electricity, Oil & Gas',
  'Electronics',
  'Elevators',
  'Embroidery',
  'Energy, Utilities & Waste',
  'Engineering Software',
  'Enterprise Artificial Intelligence',
  'Enterprise Resource Planning (ERP) Software',
  'Environment, Health, Safety, Social & Governance Software',
  'EV Charging Stations',
  'Facilities Management & Commercial Cleaning',
  'Federal',
  'Finance',
  'Financial Software',
  'Fire Proofing & Barriers',
  'Fitness & Dance Facilities',
  'Flowers, Gifts & Speciality Stores',
  'Food & Beverage',
  'Food Service',
  'Forestry',
  'Funeral Homes & Funeral Related Services',
  'Furniture',
  'Gambling & Gaming',
  'Glass & Clay',
  'Government',
  'Grocery Retail',
  'Hand, Power & Lawn-care Tools',
  'Health & Nutrition Products',
  'Healthcare Services',
  'Healthcare Software',
  'Holding Companies & Conglomerates',
  'Home Improvement & Hardware Retail',
  'Hospitality',
  'Hospitals & Physicians Clinics',
  'Household Goods',
  'Human Resources Software',
  'HVAC',
  'Industrial Machinery & Equipment',
  'Information & Document Management',
  'Insurance',
  'Internet Service Providers, Website Hosting & Internet-related Services',
  'Investment Banking',
  'IT/Cyber Security/MSP',
  'Jewelry & Watch Retail',
  'K-12 Schools',
  'Landscape Services',
  'Law Firms & Legal Services',
  'LED Lighting',
  'Legal Software',
  'Lending & Brokerage',
  'Libraries',
  'Local',
  'Lodging & Resorts',
  'Logistics',
  'Management Consulting',
  'Manufacturing',
  'Marine Shipping & Transportation',
  'Material Handling',
  'Media & Event Production',
  'Media & Internet',
  'Medical & Surgical Hospitals',
  'Medical Devices & Equipment',
  'Medical Laboratories & Imaging Centers',
  'Medical Specialists',
  'Membership Organizations',
  'Mental Health & Rehabilitation Facilities',
  'Merger & Acquisition',
  'Minerals & Mining',
  'Mobile App Development',
  'Mortgage - LO Recruitment',
  'Mortgage - Realtor Referral',
  'Motor Vehicles',
  'Movie Theaters',
  'Multimedia & Graphic Design',
  'Multimedia, Games & Graphics Software',
  'Museums & Art Galleries',
  'Music Production & Services',
  'Networking Software',
  'Newspapers & News Services',
  'Non-Profit & Charitable Organizations',
  'Office Products Retail & Distribution',
  'Oil & Gas Exploration & Services',
  'Organizations',
  'Other',
  'Other (Non-Local)',
  'Other Rental Stores (Furniture, A/V, Construction & Industrial Equipment)',
  'Outsourced AR & Billing',
  'Outsourced HR',
  'Painting',
  'Performing Arts Theaters',
  'Pest Control',
  'Pet Products',
  'Pharmaceuticals',
  'Photographic & Optical Equipment',
  'Photography Studio',
  'Physical Security',
  'Physicians Clinics',
  'Plastic, Packaging & Containers',
  'Plumbing',
  'Power Sweeping Solutions',
  'Power Washing',
  'Product, Research & Development',
  'Publishing',
  'Pulp & Paper',
  'Quality Assurance & Development',
  'Rail, Bus & Taxi',
  'Real Estate',
  'Real Estate Agent Recruitment',
  'Record, Video & Book Stores',
  'Religious Organizations',
  'Repair Services',
  'Restaurants',
  'Restoration/Post Disaster',
  'Retail',
  'Risk Mitigation',
  'Salesforce Consulting',
  'Script Writing',
  'Security Products & Services',
  'Security software',
  'Sheet Metal Fabrication',
  'Sign Companies',
  'Social Networks',
  'Solar',
  'Software',
  'Sporting & Recreational Equipment Retail',
  'Sporting Goods',
  'Sports Teams & Leagues',
  'Staffing',
  'State',
  'Storage & System Management Software',
  'Supply Chain Management (SCM) Software',
  'Talent Acquisition',
  'Telecommunication Equipment',
  'Telecommunications',
  'Telephony & Wireless',
  'Test & Measurement Equipment',
  'Textiles & Apparel',
  'Ticket Sales',
  'Tires & Rubber',
  'Toys & Games',
  'Training',
  'Translation & Linguistic Services',
  'Transportation',
  'Travel Agencies & Services',
  'Tribal Nations',
  'Trucking, Moving & Storage',
  'Venture Capital & Private Equity',
  'Veterinary Services',
  'Vitamins, Supplements & Health Stores',
  'Waste Treatment, Environmental Services & Recycling',
  'Watches & Jewelry',
  'Water Treatment',
  'Weight & Health Management',
  'Wire & Cable',
  'Workforce Management Software',
  'Zoos & National Parks'
];
