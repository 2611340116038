export function initTheme () {
  const $ = window.jQuery;

  $(function () {
    'use strict';

    $('.preloader').fadeOut();

    // =================================
    // Tooltip
    // =================================
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new window.bootstrap.Tooltip(tooltipTriggerEl);
    });

    // =================================
    // Popover
    // =================================
    var popoverTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="popover"]')
    );
    popoverTriggerList.map(function (popoverTriggerEl) {
      return new window.bootstrap.Popover(popoverTriggerEl);
    });

    // increment & decrement
    $('.minus,.add').on('click', function () {
      var $qty = $(this).closest('div').find('.qty'),
        currentVal = parseInt($qty.val()),
        isAdd = $(this).hasClass('add');
      !isNaN(currentVal) &&
      $qty.val(
        isAdd ? ++currentVal : currentVal > 0 ? --currentVal : currentVal
      );
    });

    // fixed header
    $(window).scroll(function () {
      if ($(window).scrollTop() >= 60) {
        $('.topbar').addClass('shadow-sm');
      } else {
        $('.topbar').removeClass('shadow-sm');
      }
    });

    const themeMode = localStorage.getItem('udab-theme-mode') || 'light';
    document.querySelector('html').setAttribute('data-bs-theme', themeMode);
  });
}
