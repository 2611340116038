<template>
  <div class="col-md-8 col-lg-6 col-xxl-3">
    <div class="card mb-0">
      <div class="card-body">
        <div class="text-nowrap logo-img text-center d-block mb-5 w-100">
          <img :src="`/assets/images/logos/${themeMode}-logo.svg`" style="width:240px; height: 36px;"
               :class="`${themeMode}-logo`" alt="Logo-light" />
        </div>
        <form>
          <div :class="['form-floating mb-3', { 'has-danger': !isFieldValid('username') }]">
            <input type="email" class="form-control" placeholder="Username" id="username" v-model="fields.username.value" />
            <label><i class="ti ti-user me-2 fs-4"></i>Username</label>
            <small class="form-control-feedback" v-if="!isFieldValid('username')">
              Please enter a username
            </small>
          </div>
          <div :class="['form-floating mb-4', { 'has-danger': !isFieldValid('password') }]">
            <input type="password"
                   class="form-control"
                   id="password"
                   placeholder="Password"
                   v-model="fields.password.value"
                   @keyup.enter="isFormValid && login()"
            />
            <label><i class="ti ti-key me-2 fs-4"></i>Password</label>
            <small class="form-control-feedback" v-if="!isFieldValid('password')">
              Please enter a password
            </small>
          </div>
          <div class="d-flex align-items-center justify-content-between mb-4">
            <router-link class="text-primary fw-medium" :to="{ name: 'forgot-password' }">
              Forgot Password ?
            </router-link>
          </div>
          <span class="btn btn-primary w-100 py-8 mb-4 rounded-2" @click="login" :disabled="isFormValid">Sign
            In</span>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useToast } from 'vue-toastification';
import { useRouter } from 'vue-router';
import { useFormValidation } from '@/helpers/form-validation';

const themeMode = ref(localStorage.getItem('udab-theme-mode') || 'light');

const toast = useToast();
const router = useRouter();

const { fields, validate, isFieldValid, isFormValid, fieldValues } = useFormValidation({
  username: { value: '', validate: 'not-empty' },
  password: { value: '', validate: 'not-empty' }
});

function login () {
  if (validate() && !isFormValid.value) {
    return;
  }

  const { username, password } = fieldValues();

  if (username !== 'admin' || password !== 'udab123') {
    toast.error('Oop! Invalid credentials');
    return;
  }

  localStorage.setItem('authToken', Math.floor(Date.now() / 1000) + 3600);
  router.push('/');
}
</script>
