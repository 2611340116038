<template>
  <div class="card bg-info-subtle shadow-none position-relative overflow-hidden mb-4">
    <div class="card-body px-4 py-3">
      <h4 class="fw-semibold mb-8">Extension</h4>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a class="text-muted text-decoration-none" href="/">Home</a>
          </li>
          <li class="breadcrumb-item" aria-current="page">Extension</li>
        </ol>
      </nav>
    </div>
  </div>
  <div class="card w-100">
    <div class="card-body">
      <div class="filters row mb-5">
        <div class="col-md-2">
          <label class="fw-bolder mb-2">Start Date</label>
          <input type="date" class="form-control" v-model="startDate" @change="getStats" />
        </div>
        <div class="col-md-2 offset-1">
          <label class="fw-bolder mb-2">End Date</label>
          <input type="date" class="form-control" v-model="endDate" @change="getStats" />
        </div>
        <div class="col-md-1 offset-6 text-right">
          <button @click="doExport" class="btn btn-light-indigo">
            <i class="fa fa-download me-2"></i>
            <span>Export</span>
          </button>
        </div>
      </div>
      <table id="stats" class="table table-hover" style="width:100%">
        <thead>
          <tr>
            <th>User</th>
            <th><i class="fa fa-save me-2"></i> Contacts</th>
            <th><i class="fa fa-save me-2"></i> Companies</th>
            <th><i class="fa fa-mouse-pointer me-2"></i> Talk Track</th>
            <th><i class="fa fa-mouse-pointer me-2"></i> CID</th>
            <th><i class="fa fa-mouse-pointer me-2"></i> Website</th>
            <th><i class="fa fa-mouse-pointer me-2"></i> Google Maps</th>
          </tr>
        </thead>
        <tbody v-if="stats">
          <tr v-for="row in stats" :key="row.User">
            <td>{{ row['User'] }}</td>
            <td>{{ row['Contact Updates'] }}</td>
            <td>{{ row['Company Updates'] }}</td>
            <td>{{ row['Click - Talk Track'] }}</td>
            <td>{{ row['Click - CID'] }}</td>
            <td>{{ row['Click - Website'] }}</td>
            <td>{{ row['Click - Google Maps'] }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th>User</th>
            <th><i class="fa fa-save me-2"></i> Contacts</th>
            <th><i class="fa fa-save me-2"></i> Companies</th>
            <th><i class="fa fa-mouse-pointer me-2"></i> Talk Track</th>
            <th><i class="fa fa-mouse-pointer me-2"></i> CID</th>
            <th><i class="fa fa-mouse-pointer me-2"></i> Website</th>
            <th><i class="fa fa-mouse-pointer me-2"></i> Google Maps</th>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, nextTick } from 'vue';
import { useRouter } from 'vue-router';
import { useApi } from '@/helpers/udab-api';

const $ = window.jQuery;
const api = useApi();
const router = useRouter();
const startDate = ref('');
const endDate = ref('');
const stats = ref([]);

let dataTable = null;

const getStats = async () => {
  if (dataTable) {
    dataTable.destroy();
    dataTable = null;
  }

  stats.value = await api.get('/extension/stats', {
    start_date: startDate.value,
    end_date: endDate.value
  });

  await nextTick();

  dataTable = $('#stats').DataTable({
    lengthMenu: [[150, 250, 500], [150, 250, 500]],
    pageLength: 150
  });
};

const doExport = async () => {
  const response = await api.get('/extension/stats/export', {
    start_date: startDate.value,
    end_date: endDate.value
  }, { responseType: 'blob' });

  const filename = 'Abstrakt Extension Stats.csv';
  const url = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

onMounted(async () => {
  const today = new Date();
  const sevenDaysInMilliseconds = 7 * 24 * 60 * 60 * 1000;
  const sevenDaysAgo = new Date(today.getTime() - sevenDaysInMilliseconds);
  startDate.value = sevenDaysAgo.toISOString().substr(0, 10);
  endDate.value = today.toISOString().substr(0, 10);

  await getStats();
});

onUnmounted(() => {
  if (dataTable) {
    dataTable.destroy();
    dataTable = null;
  }
});
</script>


<style scoped>
.card-body {
  padding: 3rem;
}

html[data-bs-theme=light] #stats :deep(tr.even td) {
  background: #f8f8f8;
}
</style>
