<template>
  <aside class="left-sidebar with-vertical">
    <div>
      <div class="brand-logo d-flex align-items-center justify-content-between">
        <a href="./main/index.html" class="text-nowrap logo-img">
          <img :src="`/assets/images/logos/${themeMode}-logo.svg`" :class="`${themeMode}-logo`" alt="Logo-light"
               style="width: 174px; height: 26px;" />
        </a>

        <a href="javascript:void(0)" class="sidebartoggler ms-auto text-decoration-none fs-5 d-block d-xl-none">
          <i class="ti ti-x"></i>
        </a>
      </div>

      <nav class="sidebar-nav scroll-sidebar" data-simplebar>
        <ul id="sidebarnav">
          <li class="sidebar-item" :class="{ 'selected': routeName === 'dashboard' }">
            <router-link class="sidebar-link" :to="{ name: 'dashboard' }" aria-expanded="false">
              <span>
                <i class="ti ti-home-2"></i>
              </span>
              <span class="hide-menu">Dashboard</span>
            </router-link>
          </li>
          <li class="nav-small-cap">
            <i class="ti ti-dots nav-small-cap-icon fs-4"></i>
            <span class="hide-menu">Clients</span>
          </li>
          <li class="sidebar-item" :class="{ 'selected': routeName.startsWith('client') }">
            <router-link class="sidebar-link" :to="{ name: 'clients' }" aria-expanded="false">
              <span>
                <i class="ti ti-list-details"></i>
              </span>
              <span class="hide-menu">Directory</span>
            </router-link>
          </li>
          <li class="sidebar-item">
            <a class="sidebar-link link-disabled" href="javascript: void(0)" aria-expanded="false">
              <span>
                <i class="ti ti-whirl"></i>
              </span>
              <span class="hide-menu">Sequencer</span>
            </a>
          </li>
          <li class="sidebar-item">
            <a class="sidebar-link link-disabled" href="javascript: void(0)" aria-expanded="false">
              <span>
                <i class="ti ti-mail"></i>
              </span>
              <span class="hide-menu">Spam Testing</span>
            </a>
          </li>
          <li class="sidebar-item">
            <a class="sidebar-link link-disabled" href="javascript: void(0)" aria-expanded="false">
              <span>
                <i class="ti ti-at-off"></i>
              </span>
              <span class="hide-menu">DNE</span>
            </a>
          </li>
          <li class="nav-small-cap">
            <i class="ti ti-dots nav-small-cap-icon fs-4"></i>
            <span class="hide-menu">Prospects</span>
          </li>
          <li class="sidebar-item">
            <a class="sidebar-link link-disabled" href="javascript: void(0)" aria-expanded="false">
              <span>
                <i class="ti ti-files"></i>
              </span>
              <span class="hide-menu">Lists</span>
            </a>
          </li>
          <li class="sidebar-item">
            <a class="sidebar-link link-disabled" href="javascript: void(0)" aria-expanded="false">
              <span>
                <i class="ti ti-cloud-upload"></i>
              </span>
              <span class="hide-menu">Uploads</span>
            </a>
          </li>
          <li class="sidebar-item">
            <a class="sidebar-link link-disabled" href="javascript: void(0)" aria-expanded="false">
              <span>
                <i class="ti ti-search"></i>
              </span>
              <span class="hide-menu">Search</span>
            </a>
          </li>
          <li class="nav-small-cap">
            <i class="ti ti-dots nav-small-cap-icon fs-4"></i>
            <span class="hide-menu">Reports</span>
          </li>
          <li class="sidebar-item" :class="{ 'selected': routeName === 'extension' }">
            <router-link class="sidebar-link" :to="{ name: 'extension' }" aria-expanded="false">
              <span>
                <i class="ti ti-align-box-bottom-center"></i>
              </span>
              <span class="hide-menu">Extension</span>
            </router-link>
          </li>
          <li class="nav-small-cap">
            <i class="ti ti-dots nav-small-cap-icon fs-4"></i>
            <span class="hide-menu">Configure</span>
          </li>
          <li class="sidebar-item">
            <a class="sidebar-link link-disabled" href="javascript: void(0)" aria-expanded="false">
              <span>
                <i class="ti ti-users"></i>
              </span>
              <span class="hide-menu">Users</span>
            </a>
          </li>
          <li class="sidebar-item" :class="{ 'selected': routeName === 'heyreach' }">
            <router-link :to="{ name: 'heyreach' }" class="sidebar-link" href="javascript: void(0)">
              <span>
                <i class="fa-2x fa-fw fa-linkedin fab fs-5"></i>
              </span>
              <span class="hide-menu">HeyReach</span>
            </router-link>
          </li>
          <li class="sidebar-item" :class="{ 'selected': routeName === 'smartlead' }">
            <router-link :to="{ name: 'smartlead' }" class="sidebar-link" href="javascript: void(0)">
              <span>
                <i class="ti ti-dna"></i>
              </span>
              <span class="hide-menu">Smartlead</span>
            </router-link>
          </li>
          <li class="sidebar-item" :class="{ 'selected': routeName === 'documentation' }">
            <router-link :to="{ name: 'documentation' }" class="sidebar-link" href="javascript: void(0)">
              <span>
                <i class="ti ti-files"></i>
              </span>
              <span class="hide-menu">Documentation</span>
            </router-link>
          </li>
          <li class="sidebar-item" :class="{ 'selected': routeName === 'settings' }">
            <router-link :to="{ name: 'settings' }" class="sidebar-link" href="javascript: void(0)">
              <span>
                <i class="ti ti-settings"></i>
              </span>
              <span class="hide-menu">Settings</span>
            </router-link>
          </li>
        </ul>
      </nav>

      <div class="fixed-profile p-3 mx-4 mb-2 bg-secondary-subtle rounded sidebar-ad mt-3">
        <div class="hstack gap-3">
          <div class="user-avatar">
            JD
          </div>
          <div class="john-title">
            <h6 class="mb-0 fs-4 fw-semibold">John Doe</h6>
            <span class="fs-2">SDR</span>
          </div>
          <a @click="logout" href="javascript: void(0)" class="border-0 bg-transparent text-primary ms-auto"
             tabindex="0" aria-label="logout" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="logout">
            <i class="ti ti-power fs-6"></i>
          </a>
        </div>
      </div>
    </div>
  </aside>
  <div class="page-wrapper">
    <div class="center" v-if="loader.isLoading()">
      <div class="wave"></div>
      <div class="wave"></div>
      <div class="wave"></div>
      <div class="wave"></div>
      <div class="wave"></div>
      <div class="wave"></div>
      <div class="wave"></div>
      <div class="wave"></div>
      <div class="wave"></div>
      <div class="wave"></div>
    </div>
    <header class="topbar">
      <div class="with-vertical">
        <nav class="navbar navbar-expand-lg p-0">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link sidebartoggler nav-icon-hover ms-n3" id="headerCollapse" href="javascript:void(0)">
                <i class="ti ti-menu-2"></i>
              </a>
            </li>
            <li class="nav-item d-none d-lg-block">
              <a class="nav-link nav-icon-hover" href="javascript:void(0)" data-bs-toggle="modal"
                 data-bs-target="#exampleModal">
                <i class="ti ti-search"></i>
              </a>
            </li>
          </ul>
          <div class="d-block d-lg-none">
            <img :src="`/assets/images/logos/${themeMode}-logo.svg`" :class="`${themeMode}-logo`" width="180" alt="" />
          </div>
          <a class="navbar-toggler nav-icon-hover p-0 border-0" href="javascript:void(0)" data-bs-toggle="collapse"
             data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="p-2">
              <i class="ti ti-dots fs-7"></i>
            </span>
          </a>
          <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
            <div class="d-flex align-items-center justify-content-between">
              <ul class="navbar-nav flex-row ms-auto align-items-center justify-content-center">
                <li class="nav-item dropdown"> <a class="nav-link nav-icon-hover" href="javascript:void(0)" id="drop2"
                                                  data-bs-toggle="dropdown" aria-expanded="false">
                                                 <i class="ti ti-bell-ringing"></i>
                                                 <div class="notification bg-primary rounded-circle"></div>
                                               </a>
                  <div class="dropdown-menu content-dd dropdown-menu-end dropdown-menu-animate-up"
                       aria-labelledby="drop2">
                    <div class="d-flex align-items-center justify-content-between py-3 px-7">
                      <h5 class="mb-0 fs-5 fw-semibold">Notifications</h5>
                      <span class="badge text-bg-primary rounded-4 px-3 py-1 lh-sm">2 new</span>
                    </div>
                    <div class="message-body" data-simplebar>
                      <a href="javascript:void(0)" class="py-6 px-7 d-flex align-items-center dropdown-item">
                        <span class="me-3">
                          <div class="user-avatar">
                            JD
                          </div>
                        </span>
                        <div class="w-75 d-inline-block v-middle">
                          <h6 class="mb-1 fw-semibold lh-base">First Notification</h6>
                          <span class="fs-2 d-block text-body-secondary">Take action</span>
                        </div>
                      </a>
                      <a href="javascript:void(0)" class="py-6 px-7 d-flex align-items-center dropdown-item">
                        <span class="me-3">
                          <div class="user-avatar">JD</div>
                        </span>
                        <div class="w-75 d-inline-block v-middle">
                          <h6 class="mb-1 fw-semibold lh-base">Second Notification</h6>
                          <span class="fs-2 d-block text-body-secondary">Take action</span>
                        </div>
                      </a>
                    </div>
                    <div class="py-6 px-7 mb-1">
                      <a href="javascript: void(0)" class="btn btn-outline-primary w-100">See All
                        Notifications</a>
                    </div>

                  </div>
                </li>
                <li class="nav-item">
                  <span class="theme-mode" @click="toggleTheme">
                    <i :class="['fa', { 'fa-moon': themeMode === 'light', 'fa-sun': themeMode === 'dark' }]"></i>
                  </span>
                </li>
                <li class="nav-item dropdown"> <a class="nav-link pe-0" href="javascript:void(0)" id="drop1"
                                                  data-bs-toggle="dropdown" aria-expanded="false">
                                                 <div class="d-flex align-items-center">
                                                   <div class="user-profile-img">
                                                     <div class="user-avatar">JD</div>
                                                   </div>
                                                 </div>
                                               </a>
                  <div class="dropdown-menu content-dd dropdown-menu-end dropdown-menu-animate-up"
                       aria-labelledby="drop1">
                    <div class="profile-dropdown position-relative" data-simplebar>
                      <div class="py-3 px-7 pb-0">
                        <h5 class="mb-0 fs-5 fw-semibold">User Profile</h5>
                      </div>
                      <div class="d-flex align-items-center py-9 mx-7 border-bottom">
                        <div class="user-avatar">JD</div>
                        <div class="ms-3">
                          <h5 class="mb-1 fs-3">John Doe</h5>
                          <span class="mb-1 d-block">SDR</span>
                          <p class="mb-0 d-flex align-items-center gap-2">
                            <i class="ti ti-mail fs-4"></i>
                            john.doe@abstraktmg.com
                          </p>
                        </div>
                      </div>
                      <div class="message-body">
                        <a href="javascript: void(0)" class="py-8 px-7 mt-8 d-flex align-items-center">
                          <span class="d-flex align-items-center justify-content-center text-bg-light rounded-1 p-6">
                            <img src="/assets/images/svgs/icon-account.svg" alt="" width="24" height="24" />
                          </span>
                          <div class="w-75 d-inline-block v-middle ps-3">
                            <h6 class="mb-1 fs-3 fw-semibold lh-base">My Profile</h6>
                          </div>
                        </a>
                      </div>
                      <div class="d-grid py-4 px-7 pt-8">
                        <a href="javascript: void(0)" @click="logout" class="btn btn-outline-primary">
                          Log Out
                        </a>
                      </div>
                    </div>

                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div class="offcanvas offcanvas-start" data-bs-scroll="true" tabindex="-1" id="mobilenavbar"
             aria-labelledby="offcanvasWithBothOptionsLabel">
          <nav class="sidebar-nav scroll-sidebar">
            <div class="offcanvas-header justify-content-between">
              <img src="/assets/images/logos/favicon.ico" alt="" class="img-fluid" />
              <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
          </nav>
        </div>
      </div>
      <div class="app-header with-horizontal">
        <nav class="navbar navbar-expand-xl container-fluid p-0">
          <ul class="navbar-nav">
            <li class="nav-item d-block d-xl-none">
              <a class="nav-link sidebartoggler ms-n3" id="sidebarCollapse" href="javascript:void(0)">
                <i class="ti ti-menu-2"></i>
              </a>
            </li>
            <li class="nav-item d-none d-xl-block">
              <a href="./main/index.html" class="text-nowrap nav-link">
                <img src="/assets/images/logos/dark-logo.svg" class="dark-logo" width="180" alt="" />
                <img src="/assets/images/logos/light-logo.svg" class="light-logo" width="180" alt="" />
              </a>
            </li>
            <li class="nav-item d-none d-xl-block">
              <a class="nav-link nav-icon-hover" href="javascript:void(0)" data-bs-toggle="modal"
                 data-bs-target="#exampleModal">
                <i class="ti ti-search"></i>
              </a>
            </li>
          </ul>
          <div class="d-block d-xl-none">
            <a href="./main/index.html" class="text-nowrap nav-link">
              <img src="/assets/images/logos/dark-logo.svg" width="180" alt="" />
            </a>
          </div>
          <a class="navbar-toggler nav-icon-hover p-0 border-0" href="javascript:void(0)" data-bs-toggle="collapse"
             data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="p-2">
              <i class="ti ti-dots fs-7"></i>
            </span>
          </a>
          <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
            <div class="d-flex align-items-center justify-content-between px-0 px-xl-8">
              <a href="javascript:void(0)"
                 class="nav-link round-40 p-1 ps-0 d-flex d-xl-none align-items-center justify-content-center"
                 type="button" data-bs-toggle="offcanvas" data-bs-target="#mobilenavbar"
                 aria-controls="offcanvasWithBothOptions">
                <i class="ti ti-align-justified fs-7"></i>
              </a>
              <ul class="navbar-nav flex-row ms-auto align-items-center justify-content-center">
                <li class="nav-item dropdown"> <a class="nav-link nav-icon-hover" href="javascript:void(0)" id="drop2"
                                                  data-bs-toggle="dropdown" aria-expanded="false">
                                                 <i class="ti ti-bell-ringing"></i>
                                                 <div class="notification bg-primary rounded-circle"></div>
                                               </a>
                  <div class="dropdown-menu content-dd dropdown-menu-end dropdown-menu-animate-up"
                       aria-labelledby="drop2">
                    <div class="d-flex align-items-center justify-content-between py-3 px-7">
                      <h5 class="mb-0 fs-5 fw-semibold">Notifications</h5>
                      <span class="badge text-bg-primary rounded-4 px-3 py-1 lh-sm">5 new</span>
                    </div>
                    <div class="message-body" data-simplebar>
                      <a href="javascript:void(0)" class="py-6 px-7 d-flex align-items-center dropdown-item">
                        <span class="me-3">
                          <div class="user-avatar">
                            JD
                          </div>
                        </span>
                        <div class="w-75 d-inline-block v-middle">
                          <h6 class="mb-1 fw-semibold lh-base">Roman Joined the Team!</h6>
                          <span class="fs-2 d-block text-body-secondary">Congratulate him</span>
                        </div>
                      </a>
                      <a href="javascript:void(0)" class="py-6 px-7 d-flex align-items-center dropdown-item">
                        <span class="me-3">
                          <div class="user-avatar">JD</div>
                        </span>
                        <div class="w-75 d-inline-block v-middle">
                          <h6 class="mb-1 fw-semibold lh-base">Roman Joined the Team!</h6>
                          <span class="fs-2 d-block text-body-secondary">Congratulate him</span>
                        </div>
                      </a>
                    </div>
                    <div class="py-6 px-7 mb-1">
                      <button class="btn btn-outline-primary w-100">See All Notifications</button>
                    </div>
                  </div>
                </li>
                <li class="nav-item dropdown"> <a class="nav-link pe-0" href="javascript:void(0)" id="drop1"
                                                  data-bs-toggle="dropdown" aria-expanded="false">
                                                 <div class="d-flex align-items-center">
                                                   <div class="user-profile-img">
                                                     <div class="user-avatar">JD</div>
                                                   </div>
                                                 </div>
                                               </a>
                  <div class="dropdown-menu content-dd dropdown-menu-end dropdown-menu-animate-up"
                       aria-labelledby="drop1">
                    <div class="profile-dropdown position-relative" data-simplebar>
                      <div class="py-3 px-7 pb-0">
                        <h5 class="mb-0 fs-5 fw-semibold">User Profile</h5>
                      </div>
                      <div class="d-flex align-items-center py-9 mx-7 border-bottom">
                        <div class="user-avatar">JD</div>
                        <div class="ms-3">
                          <h5 class="mb-1 fs-3">John Doe</h5>
                          <span class="mb-1 d-block">SDR</span>
                          <p class="mb-0 d-flex align-items-center gap-2">
                            <i class="ti ti-mail fs-4"></i>
                            john.doe@abstraktmg.com
                          </p>
                        </div>
                      </div>
                      <div class="message-body">
                        <a href="./main/page-user-profile.html" class="py-8 px-7 mt-8 d-flex align-items-center">
                          <span class="d-flex align-items-center justify-content-center text-bg-light rounded-1 p-6">
                            <img src="/assets/images/svgs/icon-account.svg" alt="" width="24" height="24" />
                          </span>
                          <div class="w-75 d-inline-block v-middle ps-3">
                            <h6 class="mb-1 fs-3 fw-semibold lh-base">My Profile</h6>
                          </div>
                        </a>
                      </div>
                      <div class="d-grid py-4 px-7 pt-8">
                        <div
                          class="upgrade-plan bg-primary-subtle position-relative overflow-hidden rounded-4 p-4 mb-9">
                          <div class="row">
                            <div class="col-6">
                              <h5 class="fs-4 mb-3 w-50 fw-semibold">Unlimited Access</h5>
                              <button class="btn btn-primary">Upgrade</button>
                            </div>
                            <div class="col-6">
                              <div class="m-n4 unlimited-img">
                                <img src="/assets/images/backgrounds/unlimited-bg.png" alt="" class="w-100" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <a href="./main/authentication-login.html" class="btn btn-outline-primary">Log Out</a>
                      </div>
                    </div>

                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>

      </div>
    </header>
    <div class="body-wrapper">
      <div class="container-fluid mw-100">
        <router-view></router-view>
      </div>
    </div>
  </div>

  <div class="modal fade" id="exampleModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
      <div class="modal-content rounded-1">
        <div class="modal-header border-bottom">
          <input type="search" class="form-control fs-3" placeholder="Search here" id="search" />
          <a href="javascript:void(0)" data-bs-dismiss="modal" class="lh-1">
            <i class="ti ti-x fs-5 ms-3"></i>
          </a>
        </div>
        <div class="modal-body message-body" data-simplebar="">
          <h5 class="mb-0 fs-5 p-1">Clients</h5>
          <ul class="list mb-0 py-2">
            <li class="p-1 mb-1 bg-hover-light-black">
              <a href="#">
                <span class="fs-3 text-dark fw-normal d-block">Independent Business Group</span>
              </a>
            </li>
            <li class="p-1 mb-1 bg-hover-light-black">
              <a href="#">
                <span class="fs-3 text-dark fw-normal d-block">TeamLogic IT - Business Lens</span>
              </a>
            </li>
          </ul>
          <h5 class="mb-0 fs-5 p-1">Sequences</h5>
          <ul class="list mb-0 py-2">
            <li class="p-1 mb-1 bg-hover-light-black">
              <a href="#">
                <span class="fs-3 text-dark fw-normal d-block">A&A Business Consulting- Strategy #1
                  [Consulting]</span>
              </a>
            </li>
            <li class="p-1 mb-1 bg-hover-light-black">
              <a href="#">
                <span class="fs-3 text-dark fw-normal d-block">A&A Business Consulting - Strategy 2
                  [Multi]</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, provide, reactive } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { initTheme } from '@/../public/assets/js/theme';
import '@vueform/multiselect/themes/default.css';

const router = useRouter();
const route = useRoute();
const routeName = ref(route.name);

const themeMode = ref(localStorage.getItem('udab-theme-mode') || 'light');
const toggleTheme = () => {
  themeMode.value = themeMode.value === 'light' ? 'dark' : 'light';
  localStorage.setItem('udab-theme-mode', themeMode.value);
  document.querySelector('html').setAttribute('data-bs-theme', themeMode.value);
};

const loader = reactive({
  value: 0,
  increment: function () {
    this.value++;
  },
  decrement: function () {
    this.value--;
  },
  isLoading: function () {
    return this.value > 0;
  }
});
provide('loader', loader);

onMounted(() => {
  initTheme();
  document.querySelectorAll('[data-simplebar]').forEach(el => new window.SimpleBar(el));
});

watch(route, (newRoute) => {
  routeName.value = newRoute.name;
});

const logout = () => {
  document.querySelectorAll('div.bs-tooltip-auto[role="tooltip"]').forEach(el => el.remove());
  localStorage.removeItem('authToken');
  router.push('/auth/login');
};
</script>

<style>
.multiselect-tag {
  --ms-tag-bg: #539bff;
}

.table-fixed {
  table-layout: fixed;
}

.center {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2a344796;
  position: absolute;
  z-index: 99999;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.wave {
  width: 5px;
  height: 100px;
  background: linear-gradient(45deg, cyan, #fff);
  margin: 10px;
  animation: wave 1s linear infinite;
  border-radius: 20px;
}

.wave:nth-child(2) {
  animation-delay: 0.1s;
}

.wave:nth-child(3) {
  animation-delay: 0.2s;
}

.wave:nth-child(4) {
  animation-delay: 0.3s;
}

.wave:nth-child(5) {
  animation-delay: 0.4s;
}

.wave:nth-child(6) {
  animation-delay: 0.5s;
}

.wave:nth-child(7) {
  animation-delay: 0.6s;
}

.wave:nth-child(8) {
  animation-delay: 0.7s;
}

.wave:nth-child(9) {
  animation-delay: 0.8s;
}

.wave:nth-child(10) {
  animation-delay: 0.9s;
}

@keyframes wave {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}
</style>
