<template>
  <tr class="align-middle">
    <td>{{ capitalizeFirstLetter(tag.object) }}</td>
    <td class="text-center">
      <TagComponent v-if="!editing" :tag="tag"/>
      <div v-if="editing" class="d-flex align-items-center justify-content-center">
        <input type="text" class="form-control mx-2" v-model="fields.name.value" />
        <input type="color" class="form-control mx-2" style="height: 39px;" v-model="fields.color.value" />
      </div>
    </td>
    <td class="text-center">
      <template v-if="!editing">
        <button class="btn btn-primary me-2" @click="deleteTag"><i class="fa fa-times"></i></button>
        <button class="btn btn-info" @click="editing=true"><i class="fa fa-edit"></i></button>
      </template>
      <template v-if="editing">
        <button class="btn btn-secondary me-2" @click="saveTag"><i class="fa fa-save"></i></button>
        <button class="btn btn-outline-danger" @click="editing=false"><i class="fa fa-times"></i></button>
      </template>
    </td>
  </tr>
</template>
<script setup>
import { defineProps, ref, defineEmits, onMounted } from 'vue';
import swal from 'sweetalert';
import TagComponent from '@/components/TagComponent.vue';
import { capitalizeFirstLetter } from '@/helpers/util';
import { useApi } from '@/helpers/udab-api';
import { useFormValidation } from '@/helpers/form-validation';

const emit = defineEmits(['deleted', 'updated']);
const { fields, validate, isFieldValid, fieldValues } = useFormValidation({});
const api = useApi();
const editing = ref(false);

const props = defineProps({
  tag: {
    type: Object,
    required: true
  }
});

onMounted(() => {
  fields.value = {
    name: { value: props.tag.name, validate: 'not-empty' },
    color: { value: props.tag.color }
  };
});

const saveTag = async () => {
  if (validate()) {
    const data = fieldValues();
    const response = await api.put(`/tags/${props.tag.id}`, data);

    if (response) {
      emit('updated');
      editing.value = false;
    }
  }
};

const deleteTag = () => {
  swal({
    title: 'Are you sure?',
    icon: 'warning',
    buttons: true,
    dangerMode: true
  })
    .then(async (confirmed) => {
      if (confirmed) {
        await api.deleteRequest(`/tags/${props.tag.id}`);
        emit('deleted');
      }
    });
};
</script>
