import { inject } from 'vue';
import axios from 'axios';
import { useToast } from 'vue-toastification';

export function useApi () {
  const toast = useToast();
  const loader = inject('loader');
  const defaultOptions = {
    showLoader: true,
    showError: true,
    responseType: 'json'
  };

  const makeRequest = async (
    method,
    url,
    data = null,
    params = {},
    options = {}
  ) => {
    options = { ...defaultOptions, ...options };

    if (options.showLoader) {
      loader.increment();
    }

    url = window.$udabApiUrl + url;
    try {
      const response = await axios({
        method,
        url,
        data,
        params,
        responseType: options.responseType
      });
      return response.data;
    } catch (error) {
      console.log(error);
      if (options.showError) {
        toast.error(
          `${error.response.status}: ${error.response.data.detail || error.response.statusText}`
        );
      }
    } finally {
      if (options.showLoader) {
        loader.decrement();
      }
    }

    return false;
  };

  const get = (url, params, options = {}) =>
    makeRequest('get', url, null, params, options);

  const post = (url, data, options = {}) =>
    makeRequest('post', url, data, {}, options);

  const put = (url, data, options = {}) =>
    makeRequest('put', url, data, {}, options);

  const patch = (url, data, options = {}) =>
    makeRequest('patch', url, data, {}, options);

  const deleteRequest = (url, data, params, options = {}) =>
    makeRequest('delete', url, data, params, options);

  return { get, post, put, patch, deleteRequest };
}
