<template>
  <div class="col-lg-4">
    <div class="text-center">
      <img src="/assets/images/backgrounds/errorimg.svg" alt="" class="img-fluid" width="500" style="margin-left: 60px">
      <h1 class="fw-semibold mb-7 fs-9">Oops!</h1>
      <h4 class="fw-semibold mb-7">The page you're looking for could not be found.</h4>
      <a class="btn btn-primary" href="/" role="button">Back to Home</a>
    </div>
  </div>
</template>
